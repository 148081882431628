import { getJSON } from 'bv-fetch';

export const fetchLiveOdds = ({
  filter, numberOfEvents, marketTypeDimension, periodDimension,
}) => (
  getJSON('/api/landing_pages/live_odds', {
    filter,
    number_of_events: numberOfEvents,
    market_type_dimension: marketTypeDimension,
    period_dimension: periodDimension,
  })
    .catch(() => ({ events: [] }))
);
