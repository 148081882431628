import { attachLinkHandlers } from 'bv-services';
import { bootFooter } from './footer';
import { bootLiveOdds } from './live_odds';

attachLinkHandlers('landingPage');

window.LandingPageComponents = {
  bootFooter,
  bootLiveOdds,
};
