import { useEffect, useState } from 'react';

const STEP = 212;

export default (wrapperRef, records, behavior = 'smooth') => {
  if (records.length <= 4) return [{ up: false, down: false }, null];

  const [scrollPosition, setScrollPosition] = useState({ level: 1, offset: 0, direction: 'down' });
  const [showArrows, setShowArrows] = useState({ up: false, down: true });

  const scrollTo = (direction = 'down') => {
    const viewPort = wrapperRef.current;
    if (viewPort) {
      if (direction === 'down') {
        setScrollPosition(() => (
          { level: scrollPosition.level + 1, offset: scrollPosition.offset + STEP, direction }));
      } else {
        setScrollPosition(() => (
          { level: scrollPosition.level - 1, offset: scrollPosition.offset - STEP, direction }));
      }
    }
  };

  useEffect(() => {
    const viewport = wrapperRef.current;

    if (scrollPosition.level !== 1) {
      setShowArrows((arrows) => ({ ...arrows, up: true }));
    } else {
      setShowArrows((arrows) => ({ ...arrows, up: false }));
    }

    if ((scrollPosition.offset + STEP) >= viewport.scrollHeight) {
      setShowArrows((arrows) => ({ ...arrows, down: false }));
    } else {
      setShowArrows((arrows) => ({ ...arrows, down: true }));
    }

    if (scrollPosition.direction === 'down') {
      viewport.scroll({ top: scrollPosition.offset, behavior });
    } else {
      viewport.scroll({ top: scrollPosition.offset, behavior });
    }
  }, [scrollPosition]);

  return [showArrows, scrollTo];
};
