import { createRoot } from 'react-dom/client';

export const bootFooter = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('footer-root');
    const root = createRoot(container);
    const { SiteFooter } = window.BVComponents;
    root.render(<SiteFooter className="lp" />);
  });
};
