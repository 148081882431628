import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import Event from './event';

const LiveOddsView = ({ events }) => events.length > 0 && (
  <div className="live-odds__wrapper lp-box--light">
    <div className="live-odds__container">
      <Carousel scrollStep={312}>
        {events.map((event) => (
          <Event
            key={event.id}
            {...event}
          />
        ))}
      </Carousel>
    </div>
  </div>
);

LiveOddsView.propTypes = {
  events: PropTypes.arrayOf(Object).isRequired,
};

export default LiveOddsView;
