import { Spinner } from 'bv-components';
import { useFetch } from 'bv-hooks';
import { fetchLiveOdds } from '../api';
import LiveOddsView from './live_odds_view';

const LiveOddsContainer = (props) => {
  const [data, loading] = useFetch(() => fetchLiveOdds(props));

  if (loading) return <Spinner />;

  return <LiveOddsView {...data} />;
};

export default LiveOddsContainer;
