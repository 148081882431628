import { createRoot } from 'react-dom/client';
import LiveOdds from './components/live_odds_container';

export const bootLiveOdds = (config) => {
  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('live-odds-root');
    const root = createRoot(container);
    root.render(<LiveOdds {...config} />);
  });
};
