import PropTypes from 'prop-types';

const Outcome = ({ description, id, price }) => (
  <a
    href={`/coupon/remotes?outcome_id=${id}`}
    className="bvs-button-sport outcome-button is-square"
  >
    <div className="outcome-n-wrapper">
      <span className="outcome-n-description">{description}</span>
      <span className="outcome-n-price">{price}</span>
    </div>
  </a>
);

Outcome.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  price: PropTypes.string.isRequired,
};

export default Outcome;
