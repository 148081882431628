import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isHorseRacing from 'Sportsbook/helpers/is_horse_racing';
import { Icon } from 'bv-components';
import { formatDate } from '../helpers/format_date';
import Outcome from './outcome';
import useVerticalScroll from '../hooks/useVerticalScroll';

const Event = ({
  competitionDescription,
  description,
  inPlay,
  market,
  startTime,
  sportEventPathId,
}) => {
  const wrapperRef = useRef();
  const [showArrows, scrollTo] = useVerticalScroll(wrapperRef, market.outcomes);

  return (
    <div className={classnames('market-view-default', { 'hr-live-odds': isHorseRacing(sportEventPathId) })}>
      <div className="live-odds__header">
        <h3 className="live-odds-event-header">{competitionDescription}</h3>
        { inPlay && <Icon id="inplay" big /> }
      </div>
      <p className="live-odds-header-title bvs-ellipsis">{description}</p>
      <p className="live-odds-header-time">{formatDate(startTime)}</p>
      <p className="live-odds-subtitle">{ isHorseRacing(sportEventPathId) ? market.placeTerms : market.description}</p>
      <span
        className={classnames('bvs-icon is-arrow-up nav-vert-previous', { 'is-active': showArrows.up })}
        onClick={() => { scrollTo('up'); }}
      />
      <div ref={wrapperRef} className="bvs-card market-view-body-wrapper">
        {
          market.outcomes.map((outcome) => <Outcome key={outcome.id} {...outcome} />)
      }
      </div>
      { showArrows.down && (
        <span
          className="bvs-icon is-arrow-down nav-vert-next is-active"
          onClick={() => { scrollTo('down'); }}
        />
      )}
    </div>
  );
};

Event.propTypes = {
  competitionDescription: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  inPlay: PropTypes.bool.isRequired,
  market: PropTypes.shape({
    description: PropTypes.string.isRequired,
    outcomes: PropTypes.arrayOf(Object).isRequired,
    placeTerms: PropTypes.string,
  }).isRequired,
  startTime: PropTypes.string.isRequired,
  sportEventPathId: PropTypes.number.isRequired,
};

export default Event;
